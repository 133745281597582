<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        1-Bromobutane undergoes dehydrohalogenation by an E2 elimination mechanism when heated with
        potassium tert-butoxide.
      </p>
      <p class="mb-2">It follows the reaction scheme below:</p>

      <v-img :src="imageHeaderName" class="mb-3" style="max-width: 500px" />

      <p class="mb-2">
        Which of the following choices properly demonstrates the series of curved arrows that
        showcases an E2 elimination reaction (Box A)?
      </p>

      <v-radio-group v-model="inputs.input1" class="mb-3 ml-3" :disabled="!allowEditing">
        <v-radio class="my-2" value="a1">
          <template #label>
            <v-img :src="imageA1Name" max-width="200px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="alpha">
          <template #label>
            <v-img :src="imageA2Name" max-width="200px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="attack">
          <template #label>
            <v-img :src="imageA3Name" max-width="200px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="electronegativity">
          <template #label>
            <v-img :src="imageA4Name" max-width="200px" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3">
        Which of the following choices properly shows the <b>major</b> organic product of E2
        reaction of 1-Bromobutane (Box B)?
      </p>

      <v-radio-group v-model="inputs.input2" class="mb-3 ml-3" :disabled="!allowEditing">
        <v-radio class="my-2" value="notPossible">
          <template #label>
            <v-img :src="imageB1Name" max-width="180px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="b2">
          <template #label>
            <v-img :src="imageB2Name" max-width="180px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="substitution">
          <template #label>
            <v-img :src="imageB3Name" max-width="180px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="extra">
          <template #label>
            <v-img :src="imageB4Name" max-width="180px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA3Q9',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    imageHeaderName() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-header.png';
    },
    imageA1Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-a1.png';
    },
    imageA2Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-a2.png';
    },
    imageA3Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-a3.png';
    },
    imageA4Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-a4.png';
    },
    imageB1Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-b1.png';
    },
    imageB2Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-b2.png';
    },
    imageB3Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-b3.png';
    },
    imageB4Name() {
      return '/img/assignments/UCIrvine/uci-51lb-a3-q9-b4.png';
    },
  },
};
</script>
